import React, { FC, PropsWithChildren, useEffect, useRef } from "react";

import { mergeClasses } from "@busbud/horizon";

import { yieldToMain } from "@app/utils/scheduler-yield";

import { DialogBody } from "./dialog-body";
import { DialogFooter } from "./dialog-footer";
import { DialogHeader } from "./dialog-header";
import { DialogSubtitle } from "./dialog-subtitle";
import { DialogTitle } from "./dialog-title";

export interface DialogProps {
  className?: string;
  id?: string;
  isOpen: boolean;
  onClose(): void;
}

export const Dialog: FC<PropsWithChildren<DialogProps>> & {
  Header: typeof DialogHeader;
  Body: typeof DialogBody;
  Footer: typeof DialogFooter;
  Title: typeof DialogTitle;
  Subtitle: typeof DialogSubtitle;
} = ({ className, isOpen, onClose, children, ...props }) => {
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    const dialogElement = dialogRef.current;

    if (!dialogElement) {
      return;
    }

    const bodyClassList = [
      "fixed",
      "overflow-hidden",
      "overscroll-contain",
      "scrollbar-gutter-stable",
      "w-screen"
    ];

    yieldToMain().then(() => {
      // Handle opening and closing the dialog
      if (isOpen && !dialogElement.open) {
        dialogElement.showModal?.();
        document.body.classList.add(...bodyClassList);
      } else if (!isOpen && dialogElement.open) {
        dialogElement.close?.();
      }
    });

    // Add the `close` event listener when the dialog is open
    if (isOpen) {
      dialogElement.addEventListener("close", onClose);
    }

    // Cleanup: remove the event listener and reset body styles
    return () => {
      if (isOpen) {
        dialogElement.removeEventListener("close", onClose);
      }
      yieldToMain().then(() => {
        document.body.classList.remove(...bodyClassList);
      });
    };
  }, [isOpen, onClose]);

  return (
    <dialog
      ref={dialogRef}
      className={mergeClasses(
        "mx-100 mb-300 mt-600 h-full max-h-[calc(100%-theme(spacing.900))] w-full max-w-[calc(100%-(theme(spacing.100)*2))] rounded-lg bg-color-elevation-floating-canvas text-color-primary shadow-elevation-floating",
        className
      )}
      {...props}
    >
      {children}
    </dialog>
  );
};

Dialog.Header = DialogHeader;
Dialog.Body = DialogBody;
Dialog.Footer = DialogFooter;
Dialog.Title = DialogTitle;
Dialog.Subtitle = DialogSubtitle;
