import React, { useCallback } from "react";

import { useDispatch } from "react-redux";

import { ButtonIcon, IconMinus, IconPlus, Label, Text } from "@busbud/horizon";

import {
  MAX_PASSENGERS_NUMBER,
  PassengerType
} from "@app/constants/passengers";
import { usePassengers } from "@app/modules/search/store/hooks/passengers";
import {
  incrementPassengersCount,
  removePassenger
} from "@app/modules/search/store/slices/search-form/passengers";

type ButtonProps = {
  onClick: () => void;
  disabled: boolean;
};

type CounterProps = {
  category: PassengerType;
};

type Props = {
  label: string;
  hint?: string;
} & CounterProps;

const MinusButton: React.FC<ButtonProps> = React.memo(
  ({ onClick, disabled, ...others }) => (
    <ButtonIcon {...others} onClick={onClick} isDisabled={disabled} size="sm">
      <IconMinus size="md" />
    </ButtonIcon>
  )
);

const PlusButton: React.FC<ButtonProps> = React.memo(
  ({ onClick, disabled, ...others }) => (
    <ButtonIcon {...others} onClick={onClick} isDisabled={disabled} size="sm">
      <IconPlus size="md" />
    </ButtonIcon>
  )
);

const Counter: React.FC<CounterProps> = ({ category }) => {
  const dispatch = useDispatch();

  const incrementCounter = useCallback(
    () => dispatch(incrementPassengersCount({ category })),
    [dispatch]
  );
  const decrementCounter = useCallback(
    () => dispatch(removePassenger({ category })),
    [dispatch]
  );

  const { passengers, total_passengers_count, can_remove_passenger } =
    usePassengers(category);
  const passengers_count = passengers[category];

  const decrement_disabled =
    total_passengers_count <= 1 ||
    passengers_count <= 0 ||
    !can_remove_passenger;
  const increment_disabled = total_passengers_count >= MAX_PASSENGERS_NUMBER;

  return (
    <div className="flex items-center gap-100">
      <MinusButton
        onClick={decrementCounter}
        disabled={decrement_disabled}
        data-testid={`${category}-decrement`}
      />
      <Label
        size="lg"
        fontWeight="bold"
        as="span"
        className="w-300 select-none text-center text-color-secondary"
      >
        {passengers_count}
      </Label>
      <PlusButton
        onClick={incrementCounter}
        disabled={increment_disabled}
        data-testid={`${category}-increment`}
      />
    </div>
  );
};

export const PassengerCounter: React.FC<Props> = ({
  label,
  hint,
  ...others
}) => {
  return (
    <div className="flex items-center justify-between gap-100">
      <div className="flex flex-col">
        <Label className="text-color-secondary" as="span" size="lg">
          {label}
        </Label>
        {!!hint && (
          <Text as="span" size="sm" className="text-color-secondary">
            {hint}
          </Text>
        )}
      </div>
      <Counter {...others} />
    </div>
  );
};
