import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DrawerType } from "@app/types/drawer";

const openDrawerReducer: CaseReducer<
  DrawerType[],
  PayloadAction<DrawerType>
> = (state, { payload: drawer }) => {
  if (drawer.replaceOnDuplicate) {
    const existent_seat_selection_drawer_idx = state.findIndex(
      state_drawer => state_drawer.type === drawer.type
    );
    if (existent_seat_selection_drawer_idx > -1) {
      state[existent_seat_selection_drawer_idx] = drawer;
      return;
    }
  }

  state.push(drawer);
};

const closeDrawerReducer: CaseReducer<DrawerType[], PayloadAction> = (
  state: DrawerType[]
) => {
  state.pop();
  return state;
};

const initialState: DrawerType[] = [];

const Drawers = createSlice({
  name: "drawers",
  initialState,
  reducers: {
    openDrawer: openDrawerReducer,
    closeDrawer: closeDrawerReducer,
    closeAllDrawers: () => initialState
  }
});

export const { openDrawer, closeDrawer, closeAllDrawers } = Drawers.actions;
export default Drawers.reducer;
