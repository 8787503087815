import "@app/modules/core/touch-device";
import "@app/polyfills/dialog-polyfill";

import { CollapseVanilla } from "@busbud/horizon";

import { initializeButtonLinks } from "@app/components/button-link/button-link-script";
import { StickyBannerVanillaJs } from "@app/components/mobile-app-promotion/sticky-banner-vanilla-js";
import { LocalStorage } from "@app/lib/bowser-storage/local-storage";
import HeaderDrawer from "@app/modules/core/vanilla/header-drawer";
import HeaderDropdowns from "@app/modules/core/vanilla/header-dropdowns";
import TrackTranslate from "@app/modules/core/vanilla/track-translate";
import UI from "@app/modules/core/vanilla/ui";

import { CountdownTimer } from "./countdown-timer";

window.addEventListener("load", () => {
  UI.init(window);
  new CountdownTimer().start();
  HeaderDropdowns();
  new HeaderDrawer();
  initializeButtonLinks();
  TrackTranslate.init();
  new CollapseVanilla();
  new StickyBannerVanillaJs(
    window.document,
    window.BB.experiments,
    window.tracker,
    LocalStorage.fromWindow(window)
  );
});
