export function range(
  start: number,
  stop?: number | undefined | null,
  step?: number | undefined | null
) {
  if (stop === undefined || stop === null) {
    stop = start || 0;
    start = 0;
  }

  step = step || 1;

  const length = Math.max(Math.ceil((stop - start) / step), 0);
  const range = Array(length);

  for (let idx = 0; idx < length; idx++, start += step) {
    range[idx] = start;
  }

  return range;
}
