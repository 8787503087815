import React from "react";

import { IsoDate } from "@app/components/calendar/Calendar.types";
import { useCalendarContext } from "@app/components/calendar/CalendarContext";
import {
  getDayOfMonth,
  getDayOfWeek,
  isBeforeDay,
  isSameDay,
  isSameMonth
} from "@app/components/calendar/date.utils";
import { Day } from "@app/components/calendar/Day/Day";

interface Props {
  selectedDate: IsoDate | null;
  weeks: IsoDate[][];
  monthNumber: number;
  year: number;
  deselectableDate: boolean;
  minSelectableDate?: IsoDate;
  setSelectedDate: (date: IsoDate | null) => void;
  onDateSelection?: (date: IsoDate) => void;
  rangeStartDate?: IsoDate;
  showRange?: boolean;
}

export const Days: React.FC<Props> = ({
  deselectableDate,
  monthNumber,
  selectedDate,
  setSelectedDate,
  weeks,
  year,
  minSelectableDate,
  onDateSelection,
  rangeStartDate,
  showRange = false
}) => {
  const { getToday } = useCalendarContext();
  const referenceDay = weeks[2][0];
  const today = getToday();
  return (
    <div
      className="grid grid-cols-7 justify-items-center gap-050"
      data-month={`month-${monthNumber}`}
      data-year={year}
    >
      {weeks.map((week: IsoDate[], weekIndex) => {
        return week.map((day: IsoDate, dayIndex) => {
          const dayOfMonth = getDayOfMonth(day);
          const dayOfWeek = getDayOfWeek(day);

          // We display empty divs for the dates before the displayed month's
          // first day and after its last day
          const isInDisplayedMonth = isSameMonth(day, referenceDay);
          if (!isInDisplayedMonth) {
            return <Day key={dayOfMonth} date={day} isHidden={true} />;
          }

          const isActive = !!selectedDate && isSameDay(selectedDate, day);

          const isPassed = isBeforeDay(day, minSelectableDate || today);

          const isDeselectable = isActive && deselectableDate;

          const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;
          const isFirstWeekend = isWeekend && dayOfMonth <= 7;
          const isLastWeekend =
            isWeekend && isLastWeekDay(day, weekIndex, dayIndex, weeks);

          return (
            <Day
              isRangeStartDate={rangeStartDate === day}
              showRange={!!rangeStartDate && showRange}
              key={dayOfMonth}
              date={day}
              isActive={isActive}
              isPassed={isPassed}
              isDeselectable={isDeselectable}
              isWeekend={isWeekend}
              isFirstWeekend={isFirstWeekend}
              isLastWeekend={isLastWeekend}
              onDateSelection={onDateSelection}
              setSelectedDate={setSelectedDate}
              isInSelectedRange={
                !!selectedDate &&
                !!rangeStartDate &&
                day >= rangeStartDate &&
                day <= selectedDate
              }
            />
          );
        });
      })}
    </div>
  );
};

/**
 * @param date
 * @param weekIndex
 * @param dayIndex
 * @param weeks
 * @returns {boolean} true if the date is the last of the weekday in the month
 * (e.g. true if it's the last Saturday)
 */
function isLastWeekDay(
  date: IsoDate,
  weekIndex: number,
  dayIndex: number,
  weeks: IsoDate[][]
): boolean {
  const nextWeek = weeks[weekIndex + 1];
  const nextWeekDay = nextWeek ? nextWeek[dayIndex] : undefined;
  const isNextWeekDayInSameMonth = nextWeekDay
    ? isSameMonth(date, nextWeekDay)
    : false;

  return !isNextWeekDayInSameMonth;
}
