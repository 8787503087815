import React, { useContext } from "react";

import { PassengerType } from "@app/constants/passengers";
import { LiteAppContext } from "@app/context/lite-app-context";

import { PassengerCounter } from "./passenger-counter";

interface Props {
  adult_only: boolean;
  category: PassengerType;
}

export const PassengerCounterInput: React.FC<Props> = props => {
  const { liteTranslator } = useContext(LiteAppContext);

  const { adult_only, category } = props;

  if (adult_only && category !== "adult") {
    return null;
  }

  let label = "";
  let hint = "";
  switch (category) {
    case "adult":
      label = adult_only
        ? liteTranslator.t("!landing.input-label.passengers")
        : liteTranslator.t("!landing.input-label.adults");
      hint = liteTranslator.t("!landing.input-label.adult-hint");
      break;
    case "child":
      label = liteTranslator.t("!landing.input-label.youth");
      hint = liteTranslator.t("!landing.input-label.youth-hint");
      break;
    case "senior":
      label = liteTranslator.t("!landing.input-label.seniors");
      hint = liteTranslator.t("!landing.input-label.senior-hint");
      break;
  }

  return (
    <div className="px-100 py-050" id={`${category}-count-container`}>
      <PassengerCounter
        label={label}
        category={category}
        hint={adult_only ? "" : hint}
      />
    </div>
  );
};
