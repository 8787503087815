import React, { useContext, useState, useEffect, ChangeEvent } from "react";

import { useSelector, useStore, useDispatch } from "react-redux";

import { Checkbox, Divider, Switch } from "@busbud/horizon";

import { PassengerType } from "@app/constants/passengers";
import { PASSENGER_TYPES } from "@app/constants/passengers";
import { LiteAppContext } from "@app/context/lite-app-context";
import { unassignAttributeToAllPassengers } from "@app/modules/search/store/slices/search-form/passengers";
import { PassengersAttributes } from "@app/modules/search/store/slices/search-form/passengers-constants";
import { LiteTranslatorService } from "@app/services/translator";
import { LandingPageReduxState } from "@app/types/landing-page";
import { ResultsReduxState } from "@app/types/results-redux-types";
import { PassengerComplete } from "@app/types/search-types";

type Props = {
  name: string;
  description: string;
  isChecked: (
    passengers: PassengerComplete,
    category: PassengerType,
    index: number
  ) => boolean;
  isDisplayed: (passengers: PassengerComplete) => boolean;
  onChange: (category: PassengerType, index: number, checked: boolean) => void;
  attribute: PassengersAttributes;
};

const getCategoryTranslation = (
  translator: LiteTranslatorService,
  category: PassengerType,
  number: number
) => {
  switch (category) {
    case "adult":
      return translator.t("!landing.input-label.adult-age", { number });
    case "child":
      return translator.t("!landing.input-label.child-age", { number });
    case "senior":
      return translator.t("!landing.input-label.senior-age", { number });
  }
};

type DetailsCheckboxProps = {
  category: PassengerType;
  index: number;
  checked: boolean;
  onChange: (category: PassengerType, index: number, checked: boolean) => void;
};

const DetailsCheckbox: React.FC<DetailsCheckboxProps> = props => {
  const { category, checked, index, onChange } = props;
  const { liteTranslator } = useContext(LiteAppContext);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(category, index, event.target.checked);
  };

  return (
    <Checkbox
      className="w-full"
      labelPosition="start"
      labelText={getCategoryTranslation(liteTranslator, category, index + 1)}
      onChange={handleChange}
      isChecked={checked}
    />
  );
};

type DetailsCheckboxListProps = {
  name: string;
  isChecked: (
    passengers: PassengerComplete,
    category: PassengerType,
    index: number
  ) => boolean;
  onChange: (category: PassengerType, index: number, checked: boolean) => void;
};

const DetailsCheckboxList: React.FC<DetailsCheckboxListProps> = props => {
  const passengers = useSelector(
    (state: LandingPageReduxState | ResultsReduxState) =>
      state.search_form.passengers
  );

  const items: React.ReactNode[] = [];

  PASSENGER_TYPES.forEach(category => {
    for (let i = 0; i < passengers[category]; i++) {
      items.push(
        <React.Fragment key={`${props.name.toLowerCase()}-${category}-${i}`}>
          <Divider />
          <div className="ps-100">
            <DetailsCheckbox
              category={category}
              index={i}
              checked={props.isChecked(passengers, category, i)}
              onChange={props.onChange}
            />
          </div>
        </React.Fragment>
      );
    }
  });

  return <div className="flex flex-col gap-100 ps-300">{items}</div>;
};

export const PassengerAttributes: React.FC<Props> = props => {
  const store = useStore<LandingPageReduxState | ResultsReduxState>();
  const [displayed, setDisplayed] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { attribute } = props;
    dispatch(unassignAttributeToAllPassengers({ attribute }));
    setDisplayed(event.target.checked);
  };

  useEffect(() => {
    setDisplayed(props.isDisplayed(store.getState().search_form.passengers));
  }, []);

  return (
    <>
      <Switch
        labelPosition="start"
        id={props.attribute}
        onChange={handleChange}
        isChecked={displayed}
        labelText={props.name}
        helperText={props.description}
      />
      {!!displayed && (
        <DetailsCheckboxList
          name={props.name}
          isChecked={props.isChecked}
          onChange={props.onChange}
        />
      )}
    </>
  );
};
