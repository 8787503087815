import React from "react";

import clsx from "clsx";

import { IconXMark, Label, UnstyledButton } from "@busbud/horizon";

import { IsoDate } from "@app/components/calendar/Calendar.types";
import { useCalendarContext } from "@app/components/calendar/CalendarContext";
import { getDayOfMonth } from "@app/components/calendar/date.utils";

const noop = (): void => {};

interface Props {
  date: IsoDate;
  isHidden?: boolean;
  isActive?: boolean;
  isPassed?: boolean;
  isDeselectable?: boolean;
  isWeekend?: boolean;
  isFirstWeekend?: boolean;
  isLastWeekend?: boolean;
  setSelectedDate?: (date: IsoDate | null) => void;
  onDateSelection?: (date: IsoDate) => void;
  isRangeStartDate?: boolean;
  showRange?: boolean;
  isInSelectedRange?: boolean;
}

export const Day: React.FC<Props> = props => {
  const {
    date,
    isActive = false,
    isPassed = false,
    isDeselectable = false,
    isWeekend = false,
    isFirstWeekend = false,
    isLastWeekend = false,
    isHidden = false,
    onDateSelection,
    setSelectedDate = noop,
    isRangeStartDate = false,
    showRange = false,
    isInSelectedRange = false
  } = props;
  const { translations } = useCalendarContext();

  const onSelection = (): void => {
    if (isPassed) {
      return;
    }

    const newDate = isDeselectable ? null : date;

    setSelectedDate(newDate);
    if (onDateSelection) {
      onDateSelection(newDate || "");
    }
  };

  if (isHidden) {
    return <UnstyledButton className="passed h-500 w-500" isDisabled />;
  }

  return (
    <UnstyledButton
      className={clsx(
        "relative flex aspect-square w-full items-center justify-center rounded-pill transition-all",
        {
          // weekend styles
          ["after:absolute after:-inset-025 after:bg-color-elevation-sunken-film"]:
            isWeekend,
          ["after:rounded-t-pill"]: isFirstWeekend,
          ["after:rounded-b-pill"]: isLastWeekend,

          // active dates styles
          ["active bg-color-scheme-interactive-selected-500 hover:bg-color-scheme-interactive-selected-400"]:
            isActive || isRangeStartDate,

          // styles for valid dates
          ["hover:bg-color-scheme-interactive-selected-100"]:
            !isPassed && !isRangeStartDate && !isActive,

          // range styles
          ["before:absolute before:-inset-025 before:bg-color-scheme-interactive-selected-100 before:outline-width-lg before:outline-color-scheme-interactive-selected-100"]:
            !isPassed && showRange,
          ["before:rounded-l-pill"]: isRangeStartDate && showRange,
          ["before:rounded-r-pill"]: isDeselectable && showRange,
          ["before:bg-color-static-transparent"]: !isInSelectedRange
        }
      )}
      aria-selected={isActive}
      ariaLabel={`${date} ${isActive ? translations.selectedDay : ""}`}
      onClick={onSelection}
      data-passed={isPassed}
      isDisabled={isPassed}
      data-testid="calendar-day"
    >
      {!!isDeselectable && (
        <div className="absolute -right-100 -top-100 block rounded-xs bg-color-elevation-raised-glass p-050 shadow-elevation-raised backdrop-blur-lg">
          <IconXMark size="sm" className="text-icon-color-primary" />
        </div>
      )}

      <Label
        size="md"
        fontWeight="bold"
        as="span"
        className={clsx("text-center", {
          ["text-color-primary-inverse hover:text-color-primary-inverse"]:
            isActive || isRangeStartDate,
          ["text-color-scheme-interactive-disabled-400 hover:text-color-scheme-interactive-disabled-400"]:
            isPassed
        })}
      >
        {getDayOfMonth(date)}
      </Label>
    </UnstyledButton>
  );
};
