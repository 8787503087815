import React, { Suspense } from "react";

import loadable from "@loadable/component";
import { connect } from "react-redux";

import type { ModalType } from "@app/types/modal";

const LazyEmailCollection = loadable(
  () =>
    import(
      /* webpackChunkName: "email-collection" */
      "./email-collection/email-collection"
    ),
  { ssr: false }
);

// This Modal Manager aims to keep the landing pages bundle as small as possible
// so we avoid importing content related to checkout or results
interface OwnProps {}

interface ReduxProps {
  modal: (ModalType & { input_element?: Element | undefined }) | null;
}

const ModalManagerLanding: React.FC<ReduxProps & OwnProps> = props => {
  if (typeof window !== "undefined") {
    window.is_page_hydrated = true;
  }

  const { modal } = props;

  if (!modal) {
    return null;
  }

  switch (modal.type) {
    case "email_collection":
      return (
        <LazyModalWrapper>
          <LazyEmailCollection />
        </LazyModalWrapper>
      );

    default:
      return null;
  }
};

const mapStateToProps = (state: {
  modals: (ModalType & { input_element?: Element | undefined })[];
}) => {
  const modal = state.modals[state.modals.length - 1] || null;

  return { modal };
};

const ModalManagerLandingComponent =
  connect(mapStateToProps)(ModalManagerLanding);

const LazyModalWrapper: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  return <Suspense fallback={null}>{children}</Suspense>;
};

export { ModalManagerLandingComponent as ModalManagerLanding };
