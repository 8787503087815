import * as React from "react";

import clsx from "clsx";

import { ButtonIcon, IconChevron, Label } from "@busbud/horizon";

import { useCalendarContext } from "@app/components/calendar/CalendarContext";

interface Props {
  className?: string;
  month: string;
  isMinMonth: boolean;
  isMaxMonth: boolean;
  onNextMonth: () => void;
  onPreviousMonth: () => void;
}

export const Selector: React.FC<Props> = ({
  className,
  month,
  isMinMonth,
  isMaxMonth,
  onNextMonth,
  onPreviousMonth
}) => {
  const { translations } = useCalendarContext();

  return (
    <div
      className={clsx(
        "grid grid-cols-7 items-center justify-items-center gap-050",
        className
      )}
    >
      <ButtonIcon
        size="sm"
        ariaLabel={translations.previousMonth}
        isDisabled={isMinMonth}
        onClick={onPreviousMonth}
        data-testid="previous-month"
      >
        <IconChevron variant="left" size="md" />
      </ButtonIcon>
      <Label
        className="col-span-5 text-center"
        as="span"
        size="md"
        fontWeight="bold"
        data-testid="selector-month"
      >
        {month}
      </Label>
      <ButtonIcon
        size="sm"
        ariaLabel={translations.nextMonth}
        isDisabled={isMaxMonth}
        onClick={onNextMonth}
        data-testid="next-month"
      >
        <IconChevron variant="right" size="md" />
      </ButtonIcon>
    </div>
  );
};
