// Never use moment in there, as we don't want to ship moment by default with the Calendar

import { IsoDate } from "@app/components/calendar/Calendar.types";

export function getFirstDayOfMonth(date: IsoDate): IsoDate {
  const d = new Date(date);
  const year = d.getUTCFullYear();
  let month: number | string = d.getUTCMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  }

  return `${year}-${month}-01`;
}

/**
 * This function should never be used with the last date of the month.
 * Months with difference in the last day will break.
 */

export function getNextMonthDate(date: IsoDate): IsoDate {
  const currentDate = new Date(date);
  const currentMonth = currentDate.getUTCMonth() + 1;
  const isLastMonth = currentMonth === 12;

  const dd = String(currentDate.getUTCDate()).padStart(2, "0");
  const mm = isLastMonth ? "01" : String(currentMonth + 1).padStart(2, "0");
  const yyyy = isLastMonth
    ? String(currentDate.getUTCFullYear() + 1)
    : String(currentDate.getUTCFullYear());

  return `${yyyy}-${mm}-${dd}`;
}

export function isSameMonth(date: IsoDate, comparing: IsoDate): boolean {
  return date.substr(0, 7) === comparing.substr(0, 7);
}

export function getDayOfWeek(date: IsoDate): number {
  return new Date(date).getUTCDay();
}

export function getDayOfMonth(date: IsoDate): number {
  return new Date(date).getUTCDate();
}

export function isSameDay(date: IsoDate, comparing: IsoDate): boolean {
  return date === comparing;
}

export function isBeforeDay(date: IsoDate, comparing: IsoDate): boolean {
  return date < comparing;
}
