import React, { FC, useRef } from "react";

import clsx from "clsx";

import { AdaptiveSelectorProps } from "@app/components/adaptive-selector-container/types";
import { useEventListener } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-event-listener";

export const DesktopSelectorContainer: FC<AdaptiveSelectorProps> = ({
  children,
  popover_className,
  ...props
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  // Close dropdown when clicking outside both the dropdown and the input
  useEventListener("mousedown", e => {
    for (const ref of [dropdownRef, props.anchor_element] as const) {
      if (
        !ref ||
        !("current" in ref) ||
        !ref.current ||
        !(e.target instanceof Element) ||
        ref.current.contains(e.target)
      ) {
        return;
      }
    }

    props.onClose();
  });

  return (
    <div
      ref={dropdownRef}
      className={clsx(
        "absolute z-[1300] w-[21rem] rounded-lg bg-color-elevation-floating-canvas p-250 shadow-elevation-floating",
        popover_className
      )}
    >
      {children}
    </div>
  );
};
