import * as React from "react";

import { Label } from "@busbud/horizon";

import { useCalendarContext } from "@app/components/calendar/CalendarContext";

export const Heading: React.FC<{}> = () => {
  const { getWeekdays } = useCalendarContext();

  return (
    <div className="grid h-500 grid-cols-7 content-center justify-items-center gap-050">
      {getWeekdays().map((weekDay, index) => {
        return (
          <Label
            key={index}
            as="span"
            size="sm"
            className="text-color-tertiary"
            data-testid="day-label"
          >
            {weekDay}
          </Label>
        );
      })}
    </div>
  );
};
