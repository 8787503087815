import React, { useContext } from "react";

import { useDispatch } from "react-redux";

import { PassengerType } from "@app/constants/passengers";
import { LiteAppContext } from "@app/context/lite-app-context";
import {
  assignAttributeToPassenger,
  unassignAttributeToPassenger
} from "@app/modules/search/store/slices/search-form";
import { PassengerComplete } from "@app/types/search-types";

import {
  ADULT,
  ADULT_WHEELCHAIR_CHECKBOXES,
  WHEELCHAIR,
  AGES_FIELDS
} from "../../../../modules/search/store/slices/search-form/passengers-constants";
import { PassengerAttributes } from "../passenger-attributes";

const isDisplayed = (passengers: PassengerComplete) => {
  const { adult_wheelchair, child_wheelchair, senior_wheelchair } = passengers;

  return adult_wheelchair + child_wheelchair + senior_wheelchair > 0;
};

const isChecked = (
  passengers: PassengerComplete,
  category: PassengerType,
  index: number
) => {
  // TODO: this maybe can be removed in the future, since we implemented a strategy for independent checkboxes on issue #13030
  if (category === ADULT) {
    return passengers[ADULT_WHEELCHAIR_CHECKBOXES][index] > -1;
  }

  const [, , wheelchair_ages] = AGES_FIELDS[category][WHEELCHAIR];

  return passengers[wheelchair_ages][index] > -1;
};

export const PassengerAttributesWheelchair: React.FC<{}> = () => {
  const { liteTranslator } = useContext(LiteAppContext);
  const dispatch = useDispatch();

  const onChange = (
    category: PassengerType,
    index: number,
    checked: boolean
  ) => {
    if (checked) {
      dispatch(
        assignAttributeToPassenger({ category, attribute: WHEELCHAIR, index })
      );
    } else {
      dispatch(
        unassignAttributeToPassenger({ category, attribute: WHEELCHAIR, index })
      );
    }
  };

  return (
    <PassengerAttributes
      name={liteTranslator.t("!search.wheelchair.toggle")}
      description={liteTranslator.t("!search.wheelchair.description")}
      isChecked={isChecked}
      isDisplayed={isDisplayed}
      onChange={onChange}
      attribute={WHEELCHAIR}
    />
  );
};
