import React, { FC } from "react";

import { AdaptiveSelectorProps } from "@app/components/adaptive-selector-container/types";
import { Dialog } from "@app/components/dialog";

export const MobileSelectorContainer: FC<AdaptiveSelectorProps> = ({
  children,
  dialog_title,
  dialog_actions,
  onClose,
  isOpen = true
}) => {
  return (
    <Dialog
      onClose={onClose}
      isOpen={isOpen}
      className="m-0 max-h-full max-w-full rounded-none"
    >
      <div className="flex h-full flex-col">
        <Dialog.Header onClose={onClose}>{dialog_title}</Dialog.Header>
        <Dialog.Body className="grow">{children}</Dialog.Body>
        {!!dialog_actions && <Dialog.Footer>{dialog_actions}</Dialog.Footer>}
      </div>
    </Dialog>
  );
};
