// cf https://developer.chrome.com/blog/introducing-scheduler-yield-origin-trial
export function yieldToMain(): Promise<void> {
  // Use scheduler.yield if it exists:
  if (
    "scheduler" in window &&
    window.scheduler &&
    "yield" in window.scheduler
  ) {
    return window.scheduler.yield();
  }

  // Fall back to setTimeout:
  return new Promise(resolve => {
    setTimeout(resolve, 0);
  });
}
