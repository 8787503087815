import type { ModalId } from "@app/types/modal";
import type { AmplitudeEvent } from "@app/types/tracking/common";
import type { ViewModal } from "@app/types/tracking/modal";

import { TrackingAction } from "./constants/tracking-action";

export function showedModal(
  modal_type: ModalId | string
): AmplitudeEvent<ViewModal> {
  return {
    action: TrackingAction.ViewModal,
    details: { modal_type }
  };
}
